<template>
  <div v-if="shouldRender" class="mt-4 text-center cursor-pointer" @click="sponsorClick">
    <div v-if="sponsorImg">
      <p v-if="reward.sponsor_text">
        {{ reward.sponsor_text }}
      </p>
      <img class="m-auto mt-2" :src="sponsorImg" />
    </div>
    <div v-else-if="linkText">
      <a class="underline" @click.prevent>{{ linkText }}</a>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  components: {},
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useMainStore),
    shouldRender() {
      return !!(this.reward.sponsor_text || this.reward.sponsor_link || this.sponsorImg);
    },
    linkText() {
      const text = this.reward.sponsor_text
        ? this.reward.sponsor_text
        : this.reward.sponsor_link;

      const regex = /https?:\/\//g;
      const match = regex.exec(text);

      if (match && match.length > 0) {
        return String(text).substring(match[0].length, text.length);
      }

      return text;
    },
    sponsorImg() {
      return (
        this.reward.media.sponsors?.length > 0 &&
        this.reward.media.sponsors[0]?.conversions?.small
      ) ?? false;
    },
  },
  methods: {
    sponsorClick() {
      if (this.reward.sponsor_link) {
        this.mainStore.openExternalLink(this.reward.sponsor_link);
      }
    },
  },
};
</script>

<style></style>
